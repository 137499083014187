// import { StringUtils } from "@/utils/StringUtils";
import Vue from 'vue';
import moment from "moment"
import { WebCam } from "vue-web-cam";
import { find, head, last } from "lodash";

const AWS = require('aws-sdk')

export default {
        components: {
                WebCam
        },
        props: {
                id: {},
                isNew: {},
        },
        data() {
                return {
                        isCameraOn: false,
                        frontCamera: false,

                        removing: false,
                        removeDialog: false,
                        textInputDialog: false,
                        textInputTemplate: 'extenze 0 ks 5 název můj testovací předmět stav 2 popis test zvukového vstupu',

                        log: 'log',

                        camera: null,
                        deviceId: null,
                        devices: [],

                        analysis: [],

                };
        },
        created() { },
        mounted() {
                this.$store.state.canEdit = true
                this.$store.dispatch('fetchItem', this.id)
        },
        watch: {
                item: (newValue, oldValue) => {
                        if (newValue && newValue.verified != 'true') {
                                newValue.openAt = new Date().toISOString()
                        }
                },
                camera: function(id) {
                        this.deviceId = id;
                },
                devices: function() {
                        // Once we have a list select the first one
                        let tmpDevice = last(this.devices);
                        if (tmpDevice) {
                                this.camera = tmpDevice.deviceId;
                                this.deviceId = tmpDevice.deviceId;
                        }
                }
        },
        computed: {
                device() {
                        return find(this.devices, n => n.deviceId == this.deviceId);
                },
                show() {
                        if (this.removing) return false
                        if (!this.item) return false
                        return true;
                },
                editMode() {
                        return this.$store.state.editMode
                },
                item() {
                        // return this.$store.state.items.find((x) => x.id == this.id)
                        if (this.$store.state.item && this.$store.state.item.id == this.id) {
                                return this.$store.state.item
                        }
                        return null
                },
                dataUrl() {
                        return this.item.image;
                },
        },
        methods: {
                verify() {
                        this.item.verified = 'true'
                        this.item.verifiedAt = new Date().toISOString()
                        this.debounceSave()
                        this.back()
                },
                removeImage() {
                        this.item.image = null
                        this.debounceSave()
                },
                onCapture() {
                        this.item.image = this.$refs.webcam.capture();
                        this.debounceSave()
                        this.isCameraOn = false
                },
                onStarted(stream) {
                        console.log("On Started Event", stream);
                },
                onStopped(stream) {
                        console.log("On Stopped Event", stream);
                },
                onStop() {
                        this.$refs.webcam.stop();
                },
                onStart() {
                        this.$refs.webcam.start();
                },
                onError(error) {
                        console.log("On Error Event", error);
                },
                onCameras(cameras) {
                        this.devices = cameras;
                        console.log("On Cameras Event", cameras);
                },
                onCameraChange(deviceId) {
                        this.deviceId = deviceId;
                        this.camera = deviceId;
                        console.log("On Camera Change Event", deviceId);
                },


                async removeItem() {
                        this.$store.dispatch('udpateItem', {item: this.item, isValid: -1})
                        this.removeDialog = false
                },
                async debounceSave() {
                        if (!this.editMode) return
                        if (!this.item) return

                        this.$store.state.saving = true
                        this.$store.state.saved = false

                        if (this.saveDebounceTime && this.saveDebounceId == this.item.id) {
                                clearTimeout(this.saveDebounceTime)
                        }
                        this.saveDebounceId = this.item.id
                        this.$store.state.saved = false

                        this.item.thumbImage = (this.item.image && this.item.image.length > 0) ? '1' : '0'

                        var self = this
                        // let isValid = this.anyMissingValues() > 0 ? 2 : 1
                        let isValid = 1
                        this.saveDebounceTime = setTimeout(function () {
                                self.$store.dispatch('updateItem', { item: self.item, isValid: isValid })
                        }, 1000)
                },
                // checkMandatory(value) {
                //     let invalidClass = "p-invalid";
                //     if (value === 0) return null // special case for budget
                //     if (!value || value.length < 1) {
                //         return invalidClass;
                //     }
                //     else {
                //         return null;
                //     }
                // },
                removeItem() {
                        this.removing = true
                        this.removeDialog = false
                        this.$store.dispatch("updateItem", { item: this.item, isValid: -1 })
                        // this.$toast.add({severity:'success', summary: 'Vše uloženo', life: 1000});
                        this.back()
                },
                back() {
                        // if (this.noHistory) {
                        this.$router.push("/");
                        // } else {
                        // this.$router.go(-1);
                        // }
                },
                enableEditMode() {
                        // this.$store.state.editMode = true
                        // this.$store.state.saved = true
                },
                disableEditMode() {
                        this.$store.state.editMode = false
                },
                cameraOn() {
                        // https://medium.com/theodo/a-progressive-web-application-with-vue-js-webpack-material-design-part-4-96c8c216810b
                        this.camera = null
                        this.deviceId = null
                        this.devices.splice(0, this.devices.length)
                        this.isCameraOn = !this.isCameraOn
                        // if (this.isCameraOn) {
                        //         navigator.mediaDevices
                        //                 .getUserMedia({ video: { facingMode: this.frontCamera ? "user" : "environment" } })
                        //                 .then(mediaStream => {
                        //                         this.$refs.video.srcObject = mediaStream;
                        //                         this.$refs.video.play();
                        //                 })
                        //                 .catch(error => console.error(error));
                        // } else {
                        //         this.$refs.video.pause();
                        //         this.$refs.video.src = "";
                        // }
                },
                // not used
                takePicture() {
                        // console.log('*******************************')
                        // this.log = 'taking picture'
                        // const mediaStreamTrack = this.$refs.webcam2.srcObject.getVideoTracks()[0]
                        // const imageCapture = new window.ImageCapture(mediaStreamTrack)
                        // let self = this
                        // return imageCapture.takePhoto().then(blob => {
                        //         this.log = 'blob ready ?'
                        //         if (blob) {
                        //                 this.log = 'blob ready !!!'
                        //                 var reader = new FileReader();
                        //                 reader.readAsDataURL(blob);
                        //                 this.log = 'read as data url'
                        //                 reader.onloadend = function() {
                        //                         this.log = 'get base 64'
                        //                         var base64data = reader.result;
                        //                         self.item.image = base64data
                        //                         console.log(base64data);
                        //                         this.log = 'save taking picture'
                        //                         self.debounceSave()
                        //                 }                                }
                        //         this.$refs.video.pause();
                        // })
                },
                clearTextInput() {
                        this.item.textInput = ''
                        this.debounceSave()
                },
                resetTextInput() {
                        this.item.textInput = this.textInputTemplate
                        this.debounceSave()
                },
                processTextInput() {
                        console.log('ok')
                        this.debounceSave()
                        this.textInputDialog = false
                        let keys = ['extenze', 'ks,kusů', 'název', 'stav', 'popis']
                        let keyLenght = []
                        let tmp = []
                        let text = this.item.textInput
                        if (text) {
                                text = text.toLowerCase()
                                for (let ks of keys) {
                                        let index = -1
                                        let lenght = 0
                                        for (let k of ks.split(',')) {
                                                lenght = k.length
                                                index = text.indexOf(k)
                                                if (index != -1) break
                                        }
                                        if (index == -1) {
                                                this.$toast.add({severity:'error', summary: 'Nelze zpracovat', life: 3000});
                                                return
                                        }
                                        tmp.push(index)
                                        keyLenght.push(lenght)
                                }
                                console.log(keys)
                                console.log(tmp)

                                let i = 0
                                let extenze = text.substring(tmp[i] + keyLenght[i], tmp[i+1]).trim()
                                console.log('extenze')
                                console.log(extenze)

                                i++
                                let ks = text.substring(tmp[i] + keyLenght[i], tmp[i+1]).trim()
                                console.log('ks')
                                console.log(ks)

                                i++
                                let nazev = text.substring(tmp[i] + keyLenght[i], tmp[i+1]).trim()
                                console.log('nazev')
                                console.log(nazev)

                                i++
                                let stav = text.substring(tmp[i] + keyLenght[i], tmp[i+1]).trim()
                                console.log('stav')
                                console.log(stav)

                                i++
                                let popis = text.substring(tmp[i] + keyLenght[i], tmp[i+1]).trim()
                                console.log('popis')
                                console.log(popis)

                                this.item.parts = extenze
                                this.item.amount = ks
                                this.item.name = nazev
                                this.item.state = stav
                                this.item.description = popis

                                this.debounceSave()
                        }
                },
                analyze() {
                        console.log('analyze')
                        this.ProcessImage()

                },
                //Calls DetectFaces API and shows estimated ages of detected faces
                DetectObjects(imageData) {
                        AWS.region = "eu-central-1";
                        var rekognition = new AWS.Rekognition();
                        var params = {
                                Image: {
                                        Bytes: imageData
                                },
                        };
                        let self = this
                        rekognition.detectLabels(params, function (err, data) {
                                if (err) console.log(err, err.stack); // an error occurred
                                else {
                                        console.log(data)
                                        self.analysis.splice(0, self.analysis.length)
                                        self.analysis.push(...data.Labels)
                                }
                        });
                },
                //Loads selected image and unencodes image bytes for Rekognition DetectFaces API
                ProcessImage() {
                        console.log('process image')
                        this.AnonLog();
                        var control = document.getElementById("fileToUpload");

                        let image = this.item.image
                        var jpg = true;
                        try {
                                image = atob(image.split("data:image/jpeg;base64,")[1]);
                        } catch (e) {
                                jpg = false;
                        }
                        if (jpg == false) {
                                try {
                                        image = atob(image.split("data:image/png;base64,")[1]);
                                } catch (e) {
                                        alert("Not an image file Rekognition can process");
                                        return;
                                }
                        }
                        //unencode image bytes for Rekognition DetectFaces API
                        var length = image.length;
                        let imageBytes = new ArrayBuffer(length);
                        var ua = new Uint8Array(imageBytes);
                        for (var i = 0; i < length; i++) {
                                ua[i] = image.charCodeAt(i);
                        }
                        //Call Rekognition
                        this.DetectObjects(imageBytes);
                },
                //Provides anonymous log on to AWS services
                AnonLog() {
                        // Configure the credentials provider to use your identity pool
                        AWS.config.region = 'eu-central-1'; // Region
                        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityPoolId: 'eu-central-1:aedab169-3918-4846-9a52-b19a6aceca3b',
                                accessKeyId: 'AKIA4VG2EWIP3WKKBAQF',
                                secretAccessKey: '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',
                        });
                        // Make the call to obtain credentials
                        // AWS.config.credentials.get(function () {
                        //         // Credentials will be available when this function is called.
                        //         var accessKeyId = AWS.config.credentials.accessKeyId;
                        //         var secretAccessKey = AWS.config.credentials.secretAccessKey;
                        //         var sessionToken = AWS.config.credentials.sessionToken;
                        // });
                }

        },
};
